<template>
  <div class="d-flex flex-column justify-content-center align-items-center approval p-2">
    <router-link class="back" :to="{ name: 'hand' }">
      <arrow-back />
    </router-link>
    <div class="position-relative title-container">
      <h1 class="h1 text-center">{{ $t('approvalTitle') }}</h1>
    </div>
    <div class="position-relative text-container">
      <p class="text-center">{{ $t('approvalMsg') }}</p>
    </div>
    <button class="btn btn-lg button" @click="approvalHandler">{{ $t('approvalBtn') }}</button>
    <div class="meta-container">
      <p class="text-center meta">
        {{ $t('approvalMeta') }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionTypes } from '@/store/modules/approval'
import ArrowBack from '@/components/ArrowBack.vue'
export default {
  name: 'Approval',
  components: {
    ArrowBack,
  },

  methods: {
    getCamera() {
      navigator.getUserMedia = ( navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia);

      if (navigator.getUserMedia) {
        navigator.getUserMedia (
            {
              video: true,
              audio: true
            },
            (localMediaStream) => {
              console.log("Access granted " + localMediaStream);
            },
            (err) => {
              console.log("The following error occured: " + err);
            }
        );
      } else {
        console.log("getUserMedia not supported");
      }
    },
    approvalHandler() {
      const approval = true
      this.$store.dispatch(actionTypes.setApproval, approval)
      this.getCamera()
      this.$router.push({ name: 'spec' })
    },
  },
}
</script>

<style lang="scss" scoped>
.approval {
  flex: 1;
  padding-top: 40px !important;

  @media screen and (min-width: 768px) {
    padding-top: 0.5rem !important;
  }
}

.back {
  position: absolute;
  top: 15px;
  left: 15px;

  @media screen and (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
}

.title-container {
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-top: auto;
  }
}

.text-container {
  margin-bottom: 100px;
}

.button {
  width: 100%;
  margin: 0 auto;
  max-width: 386px;
}

.meta-container {
  max-width: 683px;
  margin: 40px auto 0;

  @media (min-width: 768px) {
    margin-top: auto;
  }
}
</style>
