<template>
  <div class="d-flex flex-column align-items-center p-2">
    <router-link class="back" :to="{ name: 'lang' }">
      <arrow-back />
    </router-link>
    <div class="position-relative title-container">
      <h1 class="h1 text-center">{{ $t('handTitle') }}</h1>
    </div>
    <div class="position-relative text-container">
      <p class="text-center">{{ $t('handMsg') }}</p>
    </div>
    <div class="btn-container mx-auto d-flex flex-column flex-sm-row justify-content-center">
      <button class="btn button" data-hand="left" @click="selectHand">{{ $t('handLBtn') }}</button>
      <button class="btn button button_inverse" data-hand="right" @click="selectHand">
        {{ $t('handRBtn') }}
      </button>
    </div>
  </div>
</template>

<script>
import { actionTypes } from '@/store/modules/hand'
import ArrowBack from '@/components/ArrowBack.vue'
export default {
  name: 'Hand',
  components: {
    ArrowBack,
  },

  methods: {
    selectHand(event) {
      const hand = event.currentTarget.dataset.hand
      this.$store.dispatch(actionTypes.setHand, hand)
      this.$router.push({ name: 'approval' })
    },
  },
}
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  top: 15px;
  left: 15px;

  @media screen and (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
}

.title-container {
  margin: 40px 0;
}

.text-container {
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 100px;
  }
}

.btn-container {
  grid-gap: 24px;
  width: 100%;
  max-width: 618px;
}

.button {
  width: 100%;
}
</style>