import { render, staticRenderFns } from "./Playground.vue?vue&type=template&id=207fb167&scoped=true&"
import script from "./Playground.vue?vue&type=script&lang=js&"
export * from "./Playground.vue?vue&type=script&lang=js&"
import style0 from "./Playground.vue?vue&type=style&index=0&id=207fb167&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207fb167",
  null
  
)

export default component.exports