<template>
  <div :class="{ left: hand === 'left' }" class="playground">
    <router-link class="back" :to="{ name: 'spec' }">
      <arrow-back />
    </router-link>
    <div class="controls-container d-flex">
      <button
          class="info-button button button_inverse button_circle"
          :class="{ active: infoShow, left: hand === 'left' }"
          type="button"
          @click="infoClickHandler"
      />
      <transition name="fade">
        <recommendations :class="{ left: hand === 'left' }" v-if="infoShow" />
      </transition>
      <slider v-if="show_slider" ref="slider" :percent="percent" />
      <div v-else class="ad">{{ $t('starting') }}</div>
    </div>
    <div class="camera-container d-flex">
      <table style="width: 100%; height: 100%;">
        <tr>
          <td><iframe height="100%" width="100%" src="/p"></iframe></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/hand'
import ArrowBack from '@/components/ArrowBack.vue'
import Slider from '@/components/Slider'
import Recommendations from '@/components/Recommendations.vue'
export default {
  name: 'Playground',
  components: {
    Slider,
    ArrowBack,
    Recommendations,
  },
  data() {
    return {
      infoShow: false,
      percent: 0,
      lastupdate: 0,
      start_date: 0,
      show_slider: 0,
    }
  },
  computed: {
    ...mapGetters({
      hand: getterTypes.getHand,
    }),
  },
  mounted() {
    setInterval(() => {
      if ((new Date).getTime() - this.lastupdate > 1000) {
        this.percent = 0
        this.show_slider = this.start_date > ((new Date()).getTime() - 2000)
      }
    }, 300)
    try {
      const ws = new WebSocket("wss://static.surdo-lite.ru:3001/");
      ws.onmessage = ({data}) => {
        let ob = JSON.parse(data)
        if (ob.channel === 'SBER_START') {
          this.start_date = (new Date()).getTime()
        }
        if (ob.channel === 'SBER_NEW' && this.$refs.slider) {
          this.message =  ob.text;
          // let ob = {target: {dataset:{template: 'next'}}}
          if (!this.$refs.slider.slides[this.$refs.slider.index]) {
            this.percent = 0
          }
          if (this.message.toLowerCase().indexOf(this.$refs.slider.slides[this.$refs.slider.index].tag.toLowerCase()) !== -1) {
            // console.log(ob)
            // this.$refs.slider.slideChangeHandler(ob)
            this.percent =  JSON.parse(this.message.replace(/.*tensor\(/ , "").replace(')', ''))[0][4]
            this.lastupdate = (new Date).getTime()
          }
        }
      }
    } catch(err) {
      console.log(err);
    }
  },
  methods: {
    infoClickHandler() {
      this.infoShow = !this.infoShow
    },
  },
}
</script>

<style lang="scss" scoped>
.ad {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.back {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;

  @media screen and (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
}

.playground {
  display: grid;
  width: 100%;
  flex: 1;
  overflow: hidden;
  grid-template-rows: repeat(2, 50%);

  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 50%);
  }
}

.playground.left {
  direction: rtl;
}

.controls-container {
  grid-row-start: 2;

  @media screen and (min-width: 576px) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @media screen and (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media screen and (min-width: 992px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.camera-container {
  grid-row-start: 1;
  background-color: #000;

  @media screen and (min-width: 576px) {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @media screen and (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  @media screen and (min-width: 992px) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.info-button {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 40;

  @media (min-width: 992px) {
    position: absolute;
    top: 40px;
    left: calc(50% - 100px);
  }

  &::before,
  &:after {
    content: '';
    position: absolute;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease 0s;
  }

  &::before {
    top: calc(50% - 12px);
    left: 50%;
    border-radius: 3px;
    width: 7px;
    height: 5px;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
  }

  &::after {
    top: 50%;
    left: 50%;
    width: 5px;
    height: 15px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    transition: transform 0.2s ease-in, bottom 0.2s linear 0.2s;
  }
}

.info-button.active {
  &::after {
    height: 19px;
    top: calc(50%);
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: bottom 0.2s linear, transform 0.2s ease-in 0.2s;
  }

  &::before {
    width: 5px;
    border-radius: 0;
    height: 19px;
    top: calc(50%);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.2s linear, transform 0.2s ease-in 0.2s;
  }
}

.info-button.left {
  @media (min-width: 992px) {
    position: absolute;
    top: 40px;
    left: calc(100% - 100px);
  }
}
</style>
