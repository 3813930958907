import i18n, { selectedLocale } from '@/i18n'

const state = {
  locale: selectedLocale,
}

export const mutationTypes = {
  updateLocale: '[local] update Locale',
}

export const actionTypes = {
  setLocale: '[local] set Locale',
}

export const getterTypes = {
  getLocale: '[local] get Locale',
}

const getters = {
  [getterTypes.getLocale]: (state) => {
    return state.locale
  },
}

const mutations = {
  [mutationTypes.updateLocale](state, payload) {
    i18n.locale = payload
    state.locale = payload
    localStorage.setItem('locale', payload)
  },
}

const actions = {
  [actionTypes.setLocale](context, credentials) {
    context.commit(mutationTypes.updateLocale, credentials)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
