/* Based on https://github.com/appleple/react-modal-video/ */

<template>
  <div v-if="isOpen">
    <div
        :class="classNames.modalVideo"
        tabIndex='-1'
        role='dialog'
        :aria-label="aria.openMessage"
        @click="$emit('update:isOpen', false)"
        @keydown.esc="$emit('update:isOpen', false)"
    >
      <div :class="classNames.modalVideoBody">
        <button
            :class="classNames.modalVideoCloseBtn"
            :aria-label="aria.dismissBtnMessage"
            ref="closeBtn"
            @click="$emit('update:isOpen', false)"
        />
        <div :class="classNames.modalVideoInner">
          <div
              :class="classNames.modalVideoIframeWrap"
              :style="{'padding-bottom': paddingBottom}"
          >
            <iframe
                :src="url"
                frameBorder='0'
                :allowFullScreen="allowFullScreen"
                tabIndex='-1'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: String,
    isOpen: Boolean,
    ratio: {
      type: String,
      default: '16:9'
    },
    allowFullScreen: {
      type: Boolean,
      default: true
    },
    animationSpeed: {
      type: Number,
      default: 300
    },
    classNames: {
      type: Object,
      default: function () {
        return {
          modalVideoEffect: 'modal-video-effect',
          modalVideo: 'modal-video',
          modalVideoClose: 'modal-video-close',
          modalVideoBody: 'modal-video-body',
          modalVideoInner: 'modal-video-inner',
          modalVideoIframeWrap: 'modal-video-movie-wrap',
          modalVideoCloseBtn: 'modal-video-close-btn'
        }
      }
    },
    aria: {
      type: Object,
      default: function () {
        return {
          openMessage: 'Modal video opened',
          dismissBtnMessage: 'Close the modal video'
        }
      }
    }
  },
  methods: {
    getPadding(ratio) {
      const arr = ratio.split(':')
      const width = Number(arr[0])
      const height = Number(arr[1])
      const padding = height * 100 / width
      return padding + '%'
    }
  },
  computed: {
    paddingBottom() {
      return this.getPadding(this.ratio)
    }
  },
  watch: {
    isOpen: {
      handler: function(val) {
        this.$nextTick(() => {
          if (typeof this.$refs.closeBtn !== 'undefined') {
            this.$refs.closeBtn.focus()
          }
        });
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
$animation-speed: 0.3s;
$animation-function: ease-out;
$backdrop-color: rgba(0, 0, 0, 0.7);
$modal-bg-color: #333;

@keyframes modal-video {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $backdrop-color;
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: $animation-function;
  animation-duration: $animation-speed;
  animation-name: modal-video;
  transition: opacity $animation-speed $animation-function;
}

.modal-video-close {
  opacity: 0;

  & .modal-video-movie-wrap {
    transform: translate(0, 100px);
  }
}

.modal-video-body {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: $modal-bg-color;
  animation-timing-function: $animation-function;
  animation-duration: $animation-speed;
  animation-name: modal-video-inner;
  transform: translate(0, 0);
  transition: transform $animation-speed $animation-function;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 50px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
  // @media screen and (max-width: 1170px) {
  //   right: 15px;
  // }

  &:before {
    transform: rotate(45deg) translateY(-50%);
  }

  &:after {
    transform: rotate(-45deg) translateY(-50%);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
    border-radius: 5px;
  }
}
</style>
