const state = {
  approval: localStorage.getItem('approval'),
}

export const mutationTypes = {
  updateApproval: '[approval] update Approval',
}

export const actionTypes = {
  setApproval: '[approval] set Approval',
}

export const getterTypes = {
  getApproval: '[approval] get Approval',
}

const getters = {
  [getterTypes.getApproval]: (state) => {
    return state.approval
  },
}

const mutations = {
  [mutationTypes.updateApproval](state, payload) {
    state.approval = payload
    localStorage.setItem('approval', payload)
  },
}

const actions = {
  [actionTypes.setApproval](context, credentials) {
    context.commit(mutationTypes.updateApproval, credentials)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
