<template>
  <div class="d-flex flex-column justify-content-center align-items-center spec p-2">
    <router-link class="back" :to="{ name: 'approval' }">
      <arrow-back />
    </router-link>

    <div class="text-container mx-auto">
      <p class="text-center">
        {{ $t('specMsg') }}
      </p>
    </div>
    <div class="title-container">
      <h1 class="h2 text-center">{{ $t('specTitle') }}</h1>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-center align-items-center circle-container">
      <div class="circle">
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC1') }}</span>
        </div>
      </div>
      <div class="circle">
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC2') }}</span>
        </div>
      </div>
      <div class="circle">
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC3') }}</span>
        </div>
      </div>
    </div>

    <div class="spec__video-link" @click="video_open=true">{{ $t('video-demo') }}</div>
    <div class="title-container">
      <h2 class="h4 text-center">{{ $t('specSubTitle') }}</h2>
    </div>

    <router-link class="btn button" :to="{ name: 'playground' }">{{ $t('specBtn') }}</router-link>
    <div class="spec__text">{{ $t('video-delay') }}</div>
    <VideoModal url="demo.html" :is-open="video_open" @update:isOpen="val => video_open=val"></VideoModal>
  </div>
</template>

<script>
import ArrowBack from '@/components/ArrowBack.vue'
import Logo from '@/components/Logo'
import VideoModal from '@/components/VideoModal'
export default {
  name: 'Spec',
  data: () => {
    return {
      video_open: false,
    }
  },
  components: {
    ArrowBack,
    Logo,
    VideoModal,
  },
}
</script>

<style lang="scss" scoped>

.spec__video-link {
  color: #FEA7AF;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.spec__text {
  font-size: 14px;
  text-align: center;
  color: #171655;
}
.back {
  position: absolute;
  top: 15px;
  left: 15px;

  @media screen and (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
}

.spec {
  padding-top: 40px;

  @media screen and (min-width: 768px) {
    padding-top: 0;
  }
}

.text-container {
  max-width: 1100px;
  margin-bottom: 40px;
}

.title-container {
  margin-bottom: 40px;
}

.button {
  width: 100%;
  max-width: 386px;
}

.circle-container {
  margin-bottom: 40px;
  grid-gap: 32px;

  @media (min-width: 992px) {
    grid-gap: 66px;
  }
}

.circle {
  position: relative;
  display: flex;
  border: 4px solid transparent;
  border-radius: 50%;
  max-width: 272px;
  width: 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(#08A652, #08A652, #08A652);

  &::before {
    content: '';
    padding-top: 100%;
  }
}

.circle:nth-child(2) {
  background-image: linear-gradient(#08A652, #08A652, #08A652);
}

.circle:nth-child(3) {
  background-image: linear-gradient( #08A652, #08A652, #08A652);
}

.circle-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 40px;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.rocket {
  position: absolute;
  z-index: 10;
}

.circle:nth-child(1) .rocket {
  top: calc(50% + 24px);
  left: -12px;
}

.circle:nth-child(2) .rocket {
  top: 32px;
  left: 0;
  transform: rotate(60deg);
}

.circle:nth-child(3) .rocket {
  top: 0;
  right: 32px;
  transform: rotate(-205deg);
}
</style>
