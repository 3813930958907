import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lang from '../views/Lang.vue'
import Load from '../views/Load.vue'
import Hand from '../views/Hand.vue'
import Approval from '../views/Approval.vue'
import Spec from '../views/Spec.vue'
import Playground from '../views/Playground.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/lang',
    name: 'lang',
    component: Lang,
  },
  {
    path: '/load',
    name: 'load',
    component: Load,
  },
  {
    path: '/hand',
    name: 'hand',
    component: Hand,
  },
  {
    path: '/approval',
    name: 'approval',
    component: Approval,
  },
  {
    path: '/spec',
    name: 'spec',
    component: Spec,
  },
  {
    path: '/playground',
    name: 'playground',
    component: Playground,
  },
]

const router = new VueRouter({
  routes,
})

export default router
