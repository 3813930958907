const state = {
  hand: localStorage.getItem('hand') || 'right',
}

export const mutationTypes = {
  updateHand: '[hand] update Hand',
}

export const actionTypes = {
  setHand: '[hand] set Hand',
}

export const getterTypes = {
  getHand: '[hand] get Hand',
}

const getters = {
  [getterTypes.getHand]: (state) => {
    return state.hand
  },
}

const mutations = {
  [mutationTypes.updateHand](state, payload) {
    state.hand = payload
    localStorage.setItem('hand', payload)
  },
}

const actions = {
  [actionTypes.setHand](context, credentials) {
    context.commit(mutationTypes.updateHand, credentials)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
