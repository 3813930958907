<template>
  <div
    :class="{ load: load, gradient: gradient }"
    class="position-relative d-flex flex-column justify-content-center align-items-center main-wrapper"
  >
    <div class="stars-overlay" :class="{ in: isIn, out: isOut }" v-if="stars"></div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gradient: false,
      load: false,
      stars: false,
      isIn: false,
      isOut: false,
    }
  },
  watch: {
    $route() {
      const path = this.$route.path
      if (path === '/load') {
        this.load = true
      } else {
        this.load = false
      }

      if (path === '/hand' || path === '/approval') {
        this.gradient = true
        this.stars = true
        this.isIn = true
        this.isOut = false
      } else {
        this.gradient = false
        this.isIn = false
        this.isOut = true
        setTimeout(() => {
          this.stars = false
        }, 1800)
      }
    },
  },

  mounted() {
    if (this.$route.path === '/load') {
      this.load = true
    } else {
      this.load = false
    }
    if (this.$route.path === '/hand' || this.$route.path === '/approval') {
      this.gradient = true
      this.stars = true
      this.isIn = true
      this.isOut = false
    } else {
      this.gradient = false
      this.isIn = false
      this.isOut = true
      setTimeout(() => {
        this.stars = false
      }, 1800)
    }
  },
}
</script>
<style>
.main-wrapper.load {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.in {
  animation: in 1.8s forwards;
}

.out {
  animation: out 1.8s forwards;
}

@keyframes in {
  from {
    top: -100%;
    left: 100%;
    opacity: 0;
  }
  to {
    top: 0%;
    left: 0%;
    opacity: 1;
  }
}

@keyframes out {
  from {
    top: 0%;
    left: 0%;
    opacity: 1;
  }
  to {
    top: 100%;
    left: -100%;
    opacity: 0;
  }
}
</style>
