<template>
  <div class="position-absolute top-0 start-0 overflow-hidden load-view">
    <div ref="text" class="text-loading d-flex align-items-center">
      <span class="h4">{{ $t('loadMsg') }}</span>
      <svg width="139" height="19" viewBox="0 0 139 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.97511 4.73145H9.06709C11.6773 4.73145 14.4601 6.12942 14.4601 8.8484C14.4601 9.71028 13.7502 9.88251 13.5004 9.88251H12.3876C11.8503 9.88251 11.4088 9.63331 11.0441 8.8484C10.6221 7.94882 9.89274 7.37384 8.74135 7.37384H7.07174C4.30803 7.37384 3.55953 8.38889 3.55953 11.5102C3.55953 14.6316 4.30803 15.6462 7.07174 15.6462H8.93306C10.0844 15.6462 10.8138 15.0717 11.2362 14.1721C11.6009 13.3868 12.042 13.138 12.5793 13.138H13.6925C13.9419 13.138 14.6522 13.3102 14.6522 14.1721C14.6522 16.8911 11.869 18.289 9.25918 18.289H6.97511C2.38866 18.289 0.027832 16.1633 0.027832 11.5102C0.027832 6.85718 2.38866 4.73145 6.97511 4.73145Z"
          fill="#FFCC00"
        />
        <path
          d="M18.9321 15.398C19.4503 15.398 19.9495 15.5702 20.5059 15.5702H20.698C21.9261 15.5702 22.7896 14.7659 23.2505 13.1382H22.4443C19.1819 13.1382 17.704 11.8171 16.4949 9.05968L15.017 5.68952C14.8058 5.19152 15.0556 4.80859 15.5352 4.80859H17.5119C18.0878 4.80859 18.5674 5.17247 18.7591 5.65103L19.8341 8.25494C20.4291 9.67236 21.2926 10.4958 22.9431 10.4958H24.0945L25.7836 5.67008C25.9753 5.11493 26.474 4.80859 26.954 4.80859H28.8543C29.3336 4.80859 29.6028 5.19113 29.4489 5.65103L26.3976 14.3826C25.6106 16.6424 24.1327 18.2126 20.8893 18.2126H18.6243C18.1256 18.2126 17.7414 17.8106 17.7414 17.3317V16.2785C17.7414 15.7805 18.1252 15.3976 18.6243 15.3976H18.9321V15.398Z"
          fill="#FFCC00"
        />
        <path
          d="M39.0624 4.80957C42.6323 4.80957 44.7243 6.41787 44.7243 10.0178C44.7243 13.6177 42.6323 15.226 39.0624 15.226H34.5718V17.3323C34.5718 17.8108 34.188 18.2132 33.6889 18.2132H31.9234C31.4438 18.2132 31.0405 17.8112 31.0405 17.3323V5.6905C31.0405 5.1925 31.4239 4.80957 31.904 4.80957H39.0624ZM34.5714 7.45197V12.5836H37.9878C39.8304 12.5836 41.193 12.2011 41.193 10.0178C41.193 7.83451 39.8304 7.45158 37.9878 7.45158H34.5714V7.45197Z"
          fill="#FFCC00"
        />
        <path
          d="M47.507 8.48607C48.2361 5.49884 50.8653 4.80957 53.5905 4.80957H58.6574C59.137 4.80957 59.5403 5.19211 59.5403 5.67106V17.3517C59.5403 17.8303 59.1374 18.2136 58.6574 18.2136H45.0309C44.5512 18.2136 44.1479 17.8116 44.1479 17.3327V16.4327C44.1479 15.9541 44.5508 15.5712 45.0309 15.5712H45.7603L47.507 8.48607ZM49.3683 15.5712H56.0086V7.45197H53.2067C52.0167 7.45197 51.211 8.16029 50.9421 9.29041L49.3683 15.5712Z"
          fill="#FFCC00"
        />
        <path
          d="M69.0017 4.521C73.7994 4.521 76.544 6.53166 76.544 11.5105C76.544 16.4893 73.7994 18.5 69.0017 18.5C64.2041 18.5 61.4595 16.4893 61.4595 11.5105C61.4595 6.53166 64.2037 4.521 69.0017 4.521ZM64.9908 11.5101C64.9908 14.3823 66.1227 15.9719 69.0017 15.9719C71.8808 15.9719 73.0127 14.3823 73.0127 11.5101C73.0127 8.63757 71.8808 7.04832 69.0017 7.04832C66.1227 7.04832 64.9908 8.63796 64.9908 11.5101Z"
          fill="#FFCC00"
        />
        <path
          d="M90.5541 18.2132H88.7696C88.2899 18.2132 87.8863 17.8303 87.8863 17.3323V7.45197H85.6026C84.3935 7.45197 83.4342 8.17972 83.1268 9.32851L80.9772 17.3327C80.8427 17.8303 80.3631 18.2136 79.8644 18.2136H77.9641C77.5421 18.2136 77.2351 17.8497 77.35 17.4283L79.7299 8.44758C80.5166 5.4794 83.2227 4.80957 85.9478 4.80957H90.6309C91.0724 4.80957 91.418 5.2306 91.418 5.6905V17.3327C91.418 17.8303 91.0342 18.2132 90.5541 18.2132Z"
          fill="#FFCC00"
        />
        <path
          d="M95.3527 8.44758C96.1398 5.4794 98.8458 4.80957 101.571 4.80957H106.618C107.079 4.80957 107.424 5.21155 107.424 5.67106V17.3517C107.424 17.8303 107.021 18.2136 106.541 18.2136H104.776C104.277 18.2136 103.893 17.8307 103.893 17.3327V14.9772H97.2331L96.5999 17.3327C96.4651 17.8303 95.9858 18.2136 95.4867 18.2136H93.6441C93.1454 18.2136 92.857 17.8307 92.9915 17.3517L95.3527 8.44758ZM97.9243 12.3348H103.893V7.45197H101.225C100.016 7.45197 99.0566 8.17972 98.7492 9.32851L97.9243 12.3348Z"
          fill="#FFCC00"
        />
        <path
          d="M121.24 18.2123C120.76 18.2123 120.357 17.8103 120.357 17.3313V8.65733L115.463 17.3317C115.195 17.8103 114.599 18.2126 114.101 18.2126H110.992C110.493 18.2126 110.109 17.8107 110.109 17.3317V5.68954C110.109 5.19154 110.492 4.80862 110.992 4.80862H112.642C113.122 4.80862 113.525 5.19116 113.525 5.67011V15.0338L118.86 5.68954C119.129 5.22964 119.685 4.80862 120.146 4.80862H122.91C123.389 4.80862 123.774 5.21059 123.774 5.68954V17.3317C123.774 17.8103 123.371 18.2126 122.891 18.2126H121.24V18.2123ZM118.15 0.978562C118.266 0.691658 118.496 0.5 118.803 0.5H119.571C119.897 0.5 120.089 0.634122 120.089 0.978562C120.089 2.70232 118.822 3.75547 116.941 3.75547C115.061 3.75547 113.794 2.70232 113.794 0.978562C113.794 0.633733 113.986 0.5 114.312 0.5H115.08C115.387 0.5 115.617 0.691658 115.733 0.978562C115.905 1.39998 116.289 1.64917 116.942 1.64917C117.594 1.64917 117.978 1.39998 118.15 0.978562Z"
          fill="#FFCC00"
        />
        <path
          d="M138.089 4.80898C138.588 4.80898 138.972 5.19152 138.972 5.68991V6.57084C138.972 7.06845 138.588 7.45177 138.089 7.45177H133.905V17.3515C133.905 17.8301 133.502 18.2134 133.022 18.2134H131.256C130.777 18.2134 130.374 17.8305 130.374 17.3515V7.45138H126.19C125.691 7.45138 125.307 7.06845 125.307 6.57045V5.68952C125.307 5.19152 125.69 4.80859 126.19 4.80859H138.089V4.80898Z"
          fill="#FFCC00"
        />
      </svg>
    </div>
    <div ref="planet" class="planet">
      <img :src="require('../assets/images/planets.png')" alt="" />
    </div>

    <div class="rocket" ref="rocket">
      <img :src="require('../assets/images/rocket.png')" alt="" />
    </div>

    <div ref="wave1" class="wave-1"></div>
    <div ref="wave2" class="wave-2"></div>
    <div ref="wave3" class="wave-3"></div>
    <div ref="wave4" class="wave-4"></div>

    <svg class="svg">
      <clipPath id="wave-1" clipPathUnits="objectBoundingBox">
        <path
          d="M0.722,0.36 C0.722,0.36,0.705,0.433,0.659,0.386 C0.614,0.339,0.61,0.291,0.523,0.337 C0.436,0.383,0.405,0.38,0.386,0.327 C0.367,0.274,0.332,0.201,0.258,0.209 C0.184,0.216,0.146,0.035,0.092,0.006 C0.039,-0.024,0,0.067,0,0.067 V1 H1 C1,1,0.974,0.857,0.917,0.814 C0.86,0.771,0.867,0.701,0.861,0.628 C0.856,0.555,0.747,0.547,0.755,0.44 C0.763,0.333,0.722,0.36,0.722,0.36"
        ></path>
      </clipPath>
    </svg>
    <svg class="svg">
      <clipPath id="wave-2" clipPathUnits="objectBoundingBox">
        <path
          d="M0.02,0.017 C0.026,0.008,0.124,-0.049,0.154,0.111 C0.185,0.271,0.218,0.273,0.267,0.283 C0.316,0.292,0.301,0.294,0.356,0.38 C0.411,0.466,0.443,0.421,0.483,0.39 C0.523,0.36,0.607,0.454,0.658,0.39 C0.709,0.327,0.691,0.41,0.691,0.41 C0.691,0.41,0.677,0.502,0.719,0.566 C0.76,0.629,0.719,0.663,0.774,0.819 C0.829,0.975,1,0.779,1,0.897 C1,1,0.456,0.869,0,1 L0.02,0.017"
        ></path>
      </clipPath>
    </svg>
    <svg class="svg">
      <clipPath id="wave-3" clipPathUnits="objectBoundingBox">
        <path
          d="M0.07,0.005 C0.121,0.034,0.116,0.157,0.178,0.173 C0.24,0.189,0.242,0.184,0.266,0.249 C0.289,0.313,0.302,0.349,0.344,0.326 C0.387,0.303,0.42,0.243,0.455,0.288 C0.489,0.333,0.577,0.405,0.657,0.229 C0.736,0.053,0.693,0.292,0.693,0.292 C0.693,0.292,0.649,0.424,0.729,0.513 C0.757,0.546,0.756,0.618,0.788,0.646 C0.846,0.699,0.899,0.654,0.934,0.597 C0.97,0.54,0.997,0.584,1,0.656 C1,0.728,0.401,1,0.259,0.997 C0.118,0.961,0.038,0.751,0.038,0.751 C0.038,0.751,-0.066,-0.072,0.07,0.005"
        ></path>
      </clipPath>
    </svg>
    <svg class="svg">
      <clipPath id="wave-4" clipPathUnits="objectBoundingBox">
        <path
          d="M1,0 C1,0,0.947,0.275,0.776,0.201 C0.606,0.127,0.634,-0.141,0.404,0.275 C0.174,0.692,0,0.121,0,0.121 V1 H0.485 H1 V0"
        ></path>
      </clipPath>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Load',
  components: {},
  data() {
    return {}
  },

  mounted() {
    this.$refs.rocket.classList.add('move-start')
    this.$refs.rocket.addEventListener('animationend', () => {
      this.$refs.rocket.classList.add('move-end')
      this.$refs.wave4.classList.add('slide')
      this.$refs.wave3.classList.add('scale')
      this.$refs.planet.classList.add('disappearance')
      this.$refs.text.classList.add('disappearance')
    })
    this.$refs.text.addEventListener('animationend', () => {
      this.$router.push({ name: 'hand' })
    })
  },
}
</script>

<style lang="scss" scoped>
.load-view {
  width: 100%;
  height: 100%;
}

.text-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  grid-gap: 8px;
  z-index: 15;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 1200px) {
    top: 600px;
    left: 80px;
    transform: none;
  }
}

.planet {
  position: absolute;
  top: 140px;
  left: 65px;
  width: 1731px;
  height: 719px;
  z-index: 10;
}

.rocket {
  position: absolute;
  top: 310px;
  left: 1010px;
  z-index: 10;
  animation: rocket-start 1s forwards 1s ease;
}

.wave-1 {
  position: absolute;
  top: -123px;
  left: -62px;
  width: 1876px;
  height: 1044px;
  background-image: linear-gradient(90deg, #00c0ef 0%, #a259ec 100%);
  opacity: 0.25;
  -webkit-clip-path: url(#wave-1);
  clip-path: url(#wave-1);
  z-index: 1;
}

.wave-2 {
  position: absolute;
  top: -49px;
  left: -85px;
  width: 2072px;
  height: 860px;
  background-image: linear-gradient(90deg, #00c0ef 0%, #a259ec 100%);
  opacity: 0.25;
  -webkit-clip-path: url(#wave-2);
  clip-path: url(#wave-2);
  z-index: 1;
}

.wave-3 {
  position: absolute;
  top: 69px;
  left: -133px;
  width: 2127px;
  height: 845px;
  background-image: linear-gradient(90deg, #433eec 6.19%, #00c0ef 86.9%);
  -webkit-clip-path: url(#wave-3);
  clip-path: url(#wave-3);
  z-index: 2;
}

.wave-4 {
  position: absolute;
  top: 620px;
  left: 0;
  width: 2127px;
  height: 335px;
  background-color: #ffffff;
  -webkit-clip-path: url(#wave-4);
  clip-path: url(#wave-4);
  z-index: 2;
}

.svg {
  position: absolute;
  width: 0;
  height: 0;
}

.move-start {
  animation: start 1s forwards 1s ease;
}

.move-end {
  transform: translate(320%, -200%);
  transition: 1s ease;
  transition-delay: 2s;
}

.scale {
  animation: scale 1.5s ease 2.12s forwards;
}

.slide {
  animation: slide 1s ease 2s forwards;
}

.disappearance {
  animation: disappearance 1.5s ease 3s forwards;
}

@keyframes start {
  from {
    top: 310px;
    left: 1010px;
  }

  to {
    top: 180px;
    left: 1125px;
  }
}

@keyframes scale {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(5);
  }
}

@keyframes disappearance {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide {
  from {
    opacity: 1;
  }

  to {
    top: 100%;
    opacity: 0;
  }
}
</style>